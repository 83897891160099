import React from 'react';

export const MiniChart = ({ actual, goal, barColor }) => {

  return (
    <div className="mini-chart">
      <div className="numbers">
        <div className="actual">{actual}</div>
        <div className="separator">/</div>
        <div className="goal">{goal}</div>
      </div>
      <MiniBar color={barColor} percent={(100 / goal) * actual} />
    </div>
  );
}

const MiniBar = ({ color, percent }) => {
  return (
    <div className="mini-bar">
      <div className="progress-bar">
        <div className="progress" 
          style={ { width: `${percent}%`, backgroundColor: color ? color : "#FF8300"}}></div>
      </div>
      <div className="percent-label">{Math.round(percent)}%</div>
    </div>
  )
}