import axios from "axios";

export const apiV1 = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/v${process.env.REACT_APP_API_VERSION}`,
});

export default api;