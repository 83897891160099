import React, { useEffect, useState, useRef, useCallback } from 'react';
import { withRouter, useParams, useHistory, Prompt } from 'react-router-dom';

import api from '../../services/api';
import { Footer, StudentInput, Main, Datagrid } from '../../components';
import { useSearchParams } from '../../hooks/useSearchParams';

function Registering() {
	const searchParams = useSearchParams();
	const yearFilter = searchParams.get('year') || new Date().getFullYear();
	const { church_id } = useParams(); // church id
	const history = useHistory();

	const buttonRef = useRef(null);

	const [isBlocking, setIsBlocking] = useState(false);
	const [instructors, setInstructors] = useState();
	const [students, setStudents] = useState([]);

	useEffect(() => {
		buttonRef.current.disabled = true;
	}, []);

	useEffect(() => {
		/*
		 * Get instructors ready registered from API
		 * to set to autocomplete select component
		 */
		api
			.get(`/students?churchID=${church_id}&year=${yearFilter}`)
			.then(({ data }) => {
				if (data.length > 0) {
					let instructorsList = [];

					data.map((student) => {
						return student.instructors.map((instructor) => {
							let e = instructorsList.filter(
								(item) => item.label === instructor.name
							);
							if (!e.length) {
								instructorsList.push({
									id: instructor._id,
									value: instructor.name,
									label: instructor.name,
								});
							}
							return e;
						});
					});

					setInstructors(instructorsList);
				}
			})
			.catch((e) => {
				console.error(e);
			});
	}, [church_id, yearFilter]);

	useEffect(() => {
		if (students.length > 0) {
			setIsBlocking(true);
			buttonRef.current.disabled = false;
		} else {
			setIsBlocking(false);
			buttonRef.current.disabled = true;
		}
	}, [students]);

	const onlyTwoNames = (name) => {
		return name.split(' ').slice(0, 2).join(' ');
	};

	const handleAdding = useCallback(
		(newValue) => {
			const newStudent = {
				name: onlyTwoNames(newValue.student),
				churchID: church_id,
				instructors: newValue.instructors.map((instructor) => ({
					id: instructor.id ? instructor.id : null,
					name: onlyTwoNames(instructor.label),
				})),
			};

			setStudents((prev) => [...prev, newStudent]);
		},
		[church_id, setStudents]
	);

	const handleRemoving = useCallback(
		(index) => {
			var studentsDoc = [...students]; // make a separate copy of the array
			studentsDoc.splice(index, 1);
			setStudents(studentsDoc);
		},
		[setStudents, students]
	);

	const handleRegister = useCallback(async () => {
		try {
			buttonRef.current.disabled = true;

			console.log('POST students', students);
			await api.post(`/students`, students);
			setIsBlocking(false);
			history.push(`/church/${church_id}/view?year=${yearFilter}`);
			buttonRef.current.disabled = false;
			return;
		} catch (error) {
			console.error(error.message);
		}
	}, [students, history, church_id, yearFilter]);

	return (
		<div className="registering">
			<Main>
				<header>
					<h2 className="big">Cadastre os estudos</h2>
					<button
						onClick={() => {
							history.goBack();
						}}
						className="close"
					>
						Fechar
					</button>
				</header>

				<StudentInput
					onInclude={handleAdding}
					actualInstructors={instructors}
				/>

				<Datagrid className="stripped clean">
					<Datagrid.Body>
						{students.map(({ name, instructors }, index) => (
							<Datagrid.Line key={index}>
								<Datagrid.Column size={0.37}>{name}</Datagrid.Column>
								<Datagrid.Column size={0.53}>
									{instructors?.map((i) => i.name).join(', ')}
								</Datagrid.Column>
								<Datagrid.Column size={0.1}>
									<button
										className="btn btn-sm btn-outline-primary rounded-pill"
										onClick={() => {
											handleRemoving(index);
										}}
									>
										Remover
									</button>
								</Datagrid.Column>
							</Datagrid.Line>
						))}
					</Datagrid.Body>
				</Datagrid>
			</Main>

			<Footer>
				<button
					ref={buttonRef}
					className="btn btn-lg btn-primary rounded-pill"
					onClick={handleRegister}
				>
					Enviar
				</button>
			</Footer>

			<Prompt
				when={isBlocking}
				message={(location) => `Tem certeza que deseja sair sem salvar?`}
			/>
		</div>
	);
}

export default withRouter(Registering);
