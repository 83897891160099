import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, useParams, Link } from 'react-router-dom';

import api from '../../services/api';

import { Footer, Header, Main, Section, StudentTable } from '../../components';
import { useSearchParams } from '../../hooks/useSearchParams';

function Church() {
	const searchParams = useSearchParams();
	const yearFilter = searchParams.get('year') || new Date().getFullYear();
	const { church_id } = useParams();

	const [isLoading, setIsLoading] = useState(true);
	const [isAllowed, setIsAllowed] = useState(false);

	const [church, setChurch] = useState({});
	const [districtName, setDistrictName] = useState('');
	const [regionName, setRegionName] = useState('');
	const [conferenceName, setConferenceName] = useState('');

	const [studentsInLastYear, setStudentsInLastYear] = useState([]);
	const [students, setStudents] = useState([{}]);
	const [studentsNumber, setStudentsNumber] = useState(0);
	const [instructorsNumber, setInstructorsNumber] = useState(0);

	useEffect(() => {
		(async () => {
			try {
				const { data: churchData } = await api.get(
					`/churches/${church_id}?year=${yearFilter}`
				);
				setConferenceName(
					churchData.conference.abbr
						? churchData.conference.abbr
						: churchData.conference.name
				);
				setDistrictName(churchData.district.name);
				setRegionName(churchData.region.name);
				setChurch(churchData);
				setStudentsNumber(churchData.students.length);
				setInstructorsNumber(churchData.instructors.length);
				setStudents(
					churchData.students.map((student) => ({
						id: student._id,
						name: student.name,
						instructors: student.instructors.map((i) => i.name),
					}))
				);

				const { data: studentsInLastYearData } = await api.get(
					`/students?churchID=${church_id}&year=${Number(yearFilter - 1)}`
				);
				setStudentsInLastYear(
					studentsInLastYearData.map((student) => ({
						id: student._id,
						name: student.name,
						instructors: student.instructors.map((i) => i.name),
					}))
				);

				setIsLoading(false);
			} catch (error) {
				console.error(error);
			}
		})();
	}, [church_id, yearFilter]);

	useEffect(() => {
		setIsAllowed(
			window.localStorage.getItem(`atn-${process.env.REACT_APP_ADMIN_KEY}`)
		);
	}, []);

	const handleRemove = useCallback(
		async (id) => {
			try {
				setIsLoading(true);

				await api.delete(`/students/${id}`);

				const { data: churchData } = await api.get(
					`/churches/${church_id}?year=${yearFilter}`
				);

				setStudentsNumber(churchData.students.length);
				setInstructorsNumber(churchData.instructors.length);
				setStudents(
					churchData.students.map((student) => ({
						id: student._id,
						name: student.name,
						instructors: student.instructors.map((i) => i.name),
					}))
				);
				setIsLoading(false);
			} catch (error) {
				console.error(error);
			}
		},
		[church_id, yearFilter]
	);

	return (
		<div className="church">
			<Header
				title="Quadro de Estudantes"
				conference={conferenceName}
				region={regionName}
				district={districtName}
				church={church.name}
				type={church.type}
				isLoading={isLoading}
			/>

			<Main>
				<Section className="bg-1 pt-5 pb-5">
					<StudentTable
						isReadOnly={false}
						isLoading={isLoading}
						isAllowed={isAllowed}
						students={students}
						studentsNumber={studentsNumber}
						instructorsNumber={instructorsNumber}
						handleRemove={handleRemove}
						stripped
					/>
				</Section>

				{!isLoading && studentsInLastYear.length > 0 && (
					<Section className="bg-2 pt-5 pb-5">
						<h2 className="section__subtitle mb-4">
							Histórico {new Date().getFullYear() - 1}
						</h2>

						<StudentTable
							isReadOnly={true}
							isLoading={isLoading}
							isAllowed={isAllowed}
							students={studentsInLastYear}
							style={{ opacity: 0.7 }}
						/>
					</Section>
				)}
			</Main>

			<Footer style={{ backgroundColor: '#FAF9F8' }}>
				<Link
					to={`/register/${church_id}?year=${yearFilter}`}
					className="btn btn-lg btn-primary rounded-pill"
				>
					Cadastrar Estudo
				</Link>
			</Footer>
		</div>
	);
}

export default withRouter(Church);
