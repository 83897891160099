import React from 'react';
import { Loading } from './Loading';
import { Datagrid } from './Datagrid';

export const StudentTable = ({ 
  isReadOnly, 
  isLoading, 
  isAllowed, 
  students, 
  studentsNumber, 
  instructorsNumber,
  handleRemove,
  scrollable,
  stripped,
  ...props
}) => {
  
  if(isLoading){
    return <Loading>Carregando dados...</Loading>
  }

  if(!students || students.length === 0){
    return <p className="text-center">Nenhum cadastro encontrado. Comece a cadastrar usando o botão <strong>Cadastrar Estudo</strong>.</p>
  }
  
  return (
    <Datagrid 
      className={`blocked clean ${scrollable ? 'scrollable' : ''} ${stripped ? 'stripped' : ''}`}>
      <Datagrid.Head>
        <Datagrid.Line>
          <Datagrid.Column size={0.4}>Estudante</Datagrid.Column>
          <Datagrid.Column>Dupla/Instrutor</Datagrid.Column>
          {isAllowed && !isReadOnly && <Datagrid.Column size={0.2}>&nbsp;</Datagrid.Column >}
        </Datagrid.Line>
      </Datagrid.Head>
      <Datagrid.Body>
        { students.map( ({ id, name, instructors }, index) => (
          <Datagrid.Line key={index}>
            <Datagrid.Column size={0.4}>{name}</Datagrid.Column>
            <Datagrid.Column>{instructors?.join(', ')}</Datagrid.Column>
            {isAllowed && !isReadOnly && <Datagrid.Column size={0.2}>
              <button 
                className="btn btn-sm btn-outline-primary rounded-pill" 
                onClick={() => { if (window.confirm('Tem certeza que deseja excluir?')) handleRemove(id, index) } }>Remover</button>
            </Datagrid.Column>}
          </Datagrid.Line>
        ))}
      </Datagrid.Body>
      {!isReadOnly &&
      <Datagrid.Footer>
        <Datagrid.Line>
          <Datagrid.Column size={0.4}>{studentsNumber}</Datagrid.Column>
          <Datagrid.Column>{instructorsNumber}</Datagrid.Column>
          {isAllowed && <Datagrid.Column size={0.2}>&nbsp;</Datagrid.Column>}
        </Datagrid.Line>
      </Datagrid.Footer>}
    </Datagrid>
  )
}