import React, { useEffect, useState } from 'react';
import { withRouter, useParams, Link } from 'react-router-dom';

import api from '../../services/api';
import {
	Header,
	Main,
	Loading,
	Chart,
	Section,
	Datagrid,
} from '../../components';
import { useSearchParams } from '../../hooks/useSearchParams';

function Conference() {
	const searchParams = useSearchParams();
	const yearFilter = searchParams.get('year') || new Date().getFullYear();
	const { conference_id } = useParams();

	const [isLoading, setIsLoading] = useState(true);

	const [conferenceName, setConferenceName] = useState('');
	const [instructorsTotal, setInstructorsTotal] = useState(0);
	const [studentsTotal, setStudentsTotal] = useState(0);
	const [regions, setRegions] = useState([]);
	const [regionDepartments, setRegionDepartments] = useState([]);
	const [districtDepartments, setDistrictDepartments] = useState([]);

	const [studentsData, setStudentsData] = useState([]);
	const [instructorsData, setInstructorsData] = useState([]);

	useEffect(() => {
		api
			.get(`/conferences/${conference_id}?year=${yearFilter}`)
			.then(({ data }) => {
				setConferenceName(data.name);
				setRegions(data.regions.filter((it) => it.type === 'region'));
				setRegionDepartments(
					data.regions.filter(
						(it) => it.type === 'department' && it.regionDepartment
					)
				);
				setDistrictDepartments(
					data.regions.filter(
						(it) => it.type === 'department' && it.districtDepartment
					)
				);
				setStudentsTotal(data.numbers.studentsTotal);
				setInstructorsTotal(data.numbers.instructorsTotal);
				setStudentsData([
					{
						label: data.name,
						percent:
							(100 / data.numbers.studentsGoal) * data.numbers.studentsTotal,
					},
					{
						label: 'UCB',
						percent:
							(100 / data.numbers.ucbStudentsGoal) *
							data.numbers.ucbStudentsTotal,
					},
				]);
				setInstructorsData([
					{
						label: data.name,
						percent:
							(100 / data.numbers.instructorsGoal) *
							data.numbers.instructorsTotal,
					},
					{
						label: 'UCB',
						percent:
							(100 / data.numbers.ucbInstructorsGoal) *
							data.numbers.ucbInstructorsTotal,
					},
				]);
				setIsLoading(false);
			})
			.catch((e) => {
				console.error(e);
			});
		window.localStorage.setItem(`atn-${process.env.REACT_APP_ADMIN_KEY}`, true);
	}, [conference_id, yearFilter]);

	return (
		<>
			<Header
				title="Acompanhamento de Estudos Bíblicos"
				conference={conferenceName}
			/>
			<Main>
				<Section>
					{isLoading ? (
						<Loading>Carregando dados...</Loading>
					) : (
						<>
							<Datagrid className="blocked stripped scrollable">
								<Datagrid.Head className="invert">
									<Datagrid.Line>
										<Datagrid.Column size={0.6}>Regiões</Datagrid.Column>
										<Datagrid.Column size={0.2} align="center">
											Instrutores
										</Datagrid.Column>
										<Datagrid.Column size={0.2} align="center">
											Estudantes
										</Datagrid.Column>
									</Datagrid.Line>
								</Datagrid.Head>
								<Datagrid.Body>
									{regions.map(({ _id, name, students, instructors }) => {
										return (
											<Datagrid.Line key={_id}>
												<Datagrid.Column size={0.6}>
													<Link to={`/region/${_id}/view?year=${yearFilter}`}>
														{name}
													</Link>
												</Datagrid.Column>
												<Datagrid.Column size={0.2} align="center">
													{instructors}
												</Datagrid.Column>
												<Datagrid.Column size={0.2} align="center">
													{students}
												</Datagrid.Column>
											</Datagrid.Line>
										);
									})}
								</Datagrid.Body>
								<Datagrid.Head className="invert">
									<Datagrid.Line>
										<Datagrid.Column size={0.6}>Departamentos</Datagrid.Column>
										<Datagrid.Column size={0.2} align="center">
											Instrutores
										</Datagrid.Column>
										<Datagrid.Column size={0.2} align="center">
											Estudantes
										</Datagrid.Column>
									</Datagrid.Line>
								</Datagrid.Head>
								<Datagrid.Body>
									{districtDepartments.map(
										({ districtDepartment, students, instructors }) => {
											return (
												<Datagrid.Line key={districtDepartment._id}>
													<Datagrid.Column size={0.6}>
														<Link
															to={`/district/${districtDepartment._id}/view?year=${yearFilter}`}
														>
															{districtDepartment.name}
														</Link>
													</Datagrid.Column>
													<Datagrid.Column size={0.2} align="center">
														{instructors}
													</Datagrid.Column>
													<Datagrid.Column size={0.2} align="center">
														{students}
													</Datagrid.Column>
												</Datagrid.Line>
											);
										}
									)}
									{regionDepartments.map(
										({ regionDepartment, students, instructors }) => {
											return (
												<Datagrid.Line key={regionDepartment._id}>
													<Datagrid.Column size={0.6}>
														<Link
															to={`/church/${regionDepartment._id}/view?year=${yearFilter}`}
														>
															{regionDepartment.name}
														</Link>
													</Datagrid.Column>
													<Datagrid.Column size={0.2} align="center">
														{instructors}
													</Datagrid.Column>
													<Datagrid.Column size={0.2} align="center">
														{students}
													</Datagrid.Column>
												</Datagrid.Line>
											);
										}
									)}
								</Datagrid.Body>
								<Datagrid.Footer>
									<Datagrid.Line>
										<Datagrid.Column size={0.6}>Total</Datagrid.Column>
										<Datagrid.Column size={0.2} align="center">
											{instructorsTotal}
										</Datagrid.Column>
										<Datagrid.Column size={0.2} align="center">
											{studentsTotal}
										</Datagrid.Column>
									</Datagrid.Line>
								</Datagrid.Footer>
							</Datagrid>
							<div className="mt-2 text-center">
								<a
									href={`https://api-atn.ucb.org.br/v3/reports/churchsByDistricts?conference=${conference_id}&year=${yearFilter}`}
									className="btn btn-sm link-rose"
								>
									Exportar Relatório Detalhado (.CSV)
								</a>
							</div>
						</>
					)}
				</Section>

				<Section className="bg-1 blocked p-5 mt-4">
					{isLoading ? (
						<Loading>Carregando gráfico...</Loading>
					) : (
						<>
							<Chart
								title="Estudantes do campo em relação a UCB"
								data={studentsData}
							/>
							<hr className="mt-4 mb-5" />
							<Chart
								title="Instrutores do campo em relação a UCB"
								data={instructorsData}
							/>
						</>
					)}
				</Section>
			</Main>
		</>
	);
}

export default withRouter(Conference);
