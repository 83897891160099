import React, { useEffect, useState } from 'react';
import { withRouter, useParams, Link } from 'react-router-dom';

import api from '../../services/api';
import { Header, Main, Loading, Section, Datagrid } from '../../components';
import { useSearchParams } from '../../hooks/useSearchParams';

function Region() {
	const searchParams = useSearchParams();
	const yearFilter = searchParams.get('year') || new Date().getFullYear();
	const { region_id } = useParams();

	const [isLoading, setIsLoading] = useState(true);

	const [conferenceName, setConferenceName] = useState('');
	const [regionName, setRegionName] = useState('');
	const [instructorsTotal, setInstructorsTotal] = useState(0);
	const [studentsTotal, setStudentsTotal] = useState(0);
	const [districts, setDistricts] = useState([]);

	useEffect(() => {
		api
			.get(`/regions/${region_id}?year=${yearFilter}`)
			.then(({ data }) => {
				setRegionName(data.name);
				setConferenceName(data.conference.abbr);
				setStudentsTotal(data.numbers.studentsTotal);
				setInstructorsTotal(data.numbers.instructorsTotal);
				setDistricts(
					data.districts.sort((a, b) => a.name.localeCompare(b.name))
				);
				setIsLoading(false);
			})
			.catch((e) => {
				console.error(e);
			});
		window.localStorage.setItem(`atn-${process.env.REACT_APP_ADMIN_KEY}`, true);
	}, [region_id, yearFilter]);

	return (
		<>
			<Header
				title="Acompanhamento de Estudos Bíblicos"
				conference={conferenceName}
				region={regionName}
			/>
			<Main>
				<Section>
					{isLoading ? (
						<Loading>Carregando dados...</Loading>
					) : (
						<Datagrid className="blocked stripped scrollable">
							<Datagrid.Head>
								<Datagrid.Line>
									<Datagrid.Column size={0.6}>Distrito</Datagrid.Column>
									<Datagrid.Column size={0.2} align="center">
										Instrutores
									</Datagrid.Column>
									<Datagrid.Column size={0.2} align="center">
										Estudantes
									</Datagrid.Column>
								</Datagrid.Line>
							</Datagrid.Head>
							<Datagrid.Body>
								{districts.map(
									({ _id, name, students, instructors }, index) => {
										return (
											<Datagrid.Line key={index}>
												<Datagrid.Column size={0.6}>
													<Link to={`/district/${_id}/view?year=${yearFilter}`}>
														{name}
													</Link>
												</Datagrid.Column>
												<Datagrid.Column size={0.2} align="center">
													{instructors}
												</Datagrid.Column>
												<Datagrid.Column size={0.2} align="center">
													{students}
												</Datagrid.Column>
											</Datagrid.Line>
										);
									}
								)}
							</Datagrid.Body>
							<Datagrid.Footer>
								<Datagrid.Line>
									<Datagrid.Column size={0.6}>Total</Datagrid.Column>
									<Datagrid.Column size={0.2} align="center">
										{instructorsTotal}
									</Datagrid.Column>
									<Datagrid.Column size={0.2} align="center">
										{studentsTotal}
									</Datagrid.Column>
								</Datagrid.Line>
							</Datagrid.Footer>
						</Datagrid>
					)}
				</Section>
			</Main>
		</>
	);
}

export default withRouter(Region);
