import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';

import api from '../../services/api';
import {
	Header,
	Main,
	Loading,
	Section,
	Datagrid,
	MiniChart,
} from '../../components';
import { useSearchParams } from '../../hooks/useSearchParams';

function Admin() {
	const searchParams = useSearchParams();
	const yearFilter = searchParams.get('year') || new Date().getFullYear();

	const [conferences, setConferences] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const [instructorsTotal, setInstructorsTotal] = useState(0);
	const [instructorsGoalTotal, setInstructorsGoalTotal] = useState(0);
	const [studentsTotal, setStudentsTotal] = useState(0);
	const [studentsGoalTotal, setStudentsGoalTotal] = useState(0);

	useEffect(() => {
		api
			.get(`/conferences?year=${yearFilter}`)
			.then(({ data }) => {
				setIsLoading(false);
				setConferences(data.sort((a, b) => a.abbr.localeCompare(b.abbr)));
			})
			.catch((e) => {
				console.error(e);
			});

		window.localStorage.setItem(`atn-${process.env.REACT_APP_ADMIN_KEY}`, true);
	}, [yearFilter]);

	useEffect(() => {
		let ic = 0;
		conferences.map((conf) => {
			ic = conf.numbers.instructors + ic;
			return ic;
		});
		setInstructorsTotal(ic);

		let igt = 0;
		conferences.map((conf) => {
			igt = conf.numbers.instructorsGoal + igt;
			return igt;
		});
		setInstructorsGoalTotal(igt);

		let st = 0;
		conferences.map((conf) => {
			st = conf.numbers.students + st;
			return st;
		});
		setStudentsTotal(st);

		let sgt = 0;
		conferences.map((conf) => {
			sgt = conf.numbers.studentsGoal + sgt;
			return sgt;
		});
		setStudentsGoalTotal(sgt);
	}, [conferences]);

	return (
		<>
			<Header title="Acompanhamento de Estudos Bíblicos" />

			<Main>
				<Section>
					{isLoading ? (
						<Loading>Carregando dados...</Loading>
					) : (
						<Datagrid className="blocked-lg stripped">
							<Datagrid.Head>
								<Datagrid.Line>
									<Datagrid.Column size={0.2} align="flex-start">
										Campo
									</Datagrid.Column>
									<Datagrid.Column size={0.4} align="center">
										Instrutores
									</Datagrid.Column>
									<Datagrid.Column size={0.4} align="center">
										Estudantes
									</Datagrid.Column>
								</Datagrid.Line>
							</Datagrid.Head>

							<Datagrid.Body>
								{conferences.map(({ _id, abbr, numbers }, index) => {
									return (
										<Datagrid.Line key={index}>
											<Datagrid.Column size={0.2} align="flex-start">
												<Link to={`/conference/${_id}?year=${yearFilter}`}>
													{abbr}
												</Link>
											</Datagrid.Column>
											<Datagrid.Column size={0.4} align="center">
												<MiniChart
													actual={numbers.instructors}
													goal={numbers.instructorsGoal}
												/>
											</Datagrid.Column>
											<Datagrid.Column size={0.4} align="center">
												<MiniChart
													actual={numbers.students}
													goal={numbers.studentsGoal}
												/>
											</Datagrid.Column>
										</Datagrid.Line>
									);
								})}
							</Datagrid.Body>
							<Datagrid.Footer>
								<Datagrid.Line>
									<Datagrid.Column size={0.2} align="flex-start">
										UCB
									</Datagrid.Column>
									<Datagrid.Column size={0.4} align="center">
										<MiniChart
											actual={instructorsTotal}
											goal={instructorsGoalTotal}
											barColor="#757C56"
										/>
									</Datagrid.Column>
									<Datagrid.Column size={0.4} align="center">
										<MiniChart
											actual={studentsTotal}
											goal={studentsGoalTotal}
											barColor="#757C56"
										/>
									</Datagrid.Column>
								</Datagrid.Line>
							</Datagrid.Footer>
						</Datagrid>
					)}
				</Section>
			</Main>
		</>
	);
}

export default withRouter(Admin);
