import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Admin from './pages/Admin';
import Conference from './pages/Conference';
import Region from './pages/Region';
import District from './pages/District';
import Church from './pages/Church';
import Registering from './pages/Registering';
import NotFound from './pages/NotFound';

const Routes = () => (
	<BrowserRouter>
		<Switch>
			<Route
				exact
				path={`/admin/${process.env.REACT_APP_ADMIN_KEY}`}
				component={Admin}
			/>
			<Route exact path="/conference/:conference_id" component={Conference} />
			<Route exact path="/region/:region_id/view" component={Region} />
			<Route exact path="/district/:district_id/view" component={District} />
			<Route exact path="/church/:church_id/view" component={Church} />
			<Route exact path="/register/:church_id" component={Registering} />
			<Route component={NotFound} />
		</Switch>
	</BrowserRouter>
);

export default Routes;
