import React from 'react';

import Bar from './Bar';

export const Chart = ( props ) => {
	return (
		<div className="chart">
		
			{props.title &&
			<h2 className="chart__title">{props.title}</h2>}
			
			<div className="chart__bars">
				{props.data?.map(conf => 
					<Bar key={conf.label} label={conf.label} percent={conf.percent} />)}
			</div>
			
		</div>
	);
}