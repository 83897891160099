import React, { useState, useEffect, useRef } from 'react';
import CreatableSelect from 'react-select/creatable';

const creatableStyles = {
	control: (provided, state) => {
		return {
			...provided,
			minHeight: 'calc(1.5em + 1rem + 2px)',
			fontSize: 18,
			lineHeight: 1.5,
			borderRadius: 0,
			borderColor: 'white'
		}
	},
	placeholder: (provided, state ) => ({
		...provided,
		color: '#97998D'
	})
}

export const StudentInput = ( { onInclude, actualInstructors } ) => {
	
	const [student, setStudent] = useState('');
	const [instructors, setInstructors] = useState(null);
	// const [selectedValues, setSelectedValues] = useState('');
	
	const textRef = useRef(null);
	const selectRef = useRef(null);
	const buttonRef = useRef(null);
	
	useEffect(() => {
		buttonRef.current.disabled = true;
	}, []);
	
	useEffect(() => {
		if( student && instructors && instructors.length > 0 ){
			buttonRef.current.disabled = false;
		}
	}, [student, instructors]);
	
	const handleAdding = () => {
		onInclude( { instructors, student } );
		buttonRef.current.disabled = true;
		selectRef.current.state.value = [];
		textRef.current.value = null;
		textRef.current.focus();
	}
	
	return (
		<div className="panel d-flex flex-column flex-lg-row student-input">
		
			<div className="form-group d-flex flex-column">
			
				<label htmlFor="studentNameField">Estudante</label>
				
				<input 
					ref={textRef}
					type="text" 
					className="form-control form-control-lg student-input__text" 
					id="studentNameField" 
					defaultValue={student} 
					onChange={ (e) => { setStudent(e.target.value) }}
					placeholder="Digite um nome por vez..." />

			</div>
		
			<div className="form-group d-flex flex-column">
			
				<label htmlFor="instructorsNamesField">Instrutores</label>
				
				<CreatableSelect
					ref={selectRef}
					isMulti
					placeholder="Digite os nomes e aperte Enter ou selecione..."
					noOptionsMessage={ ({ inputValue }) => `Comece digitando um nome.` }
					onChange={setInstructors}
					defaultValue={instructors}
					options={actualInstructors}
					className="creatable"
					styles={creatableStyles} />

			</div>
		
			<div className="form-button">
				<button ref={buttonRef} className="btn btn-lg btn-outline-primary rounded-pill" onClick={handleAdding}>Incluir</button>
			</div>
		
		</div>
	);
}