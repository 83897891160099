import React from 'react';

export const Section = ({ children, className, ...props }) => { 
  return (
    <section 
      className={`section${className ? ` ${className}` : ''}`} {...props}>
      <div className="section-content">
        {children}
      </div>
    </section>
  );
}