import React from 'react';
import ReactLoading from 'react-loading';

export const Loading = ({ children, ...props }) => {
    
  return (
    <div className="loading d-flex justify-content-center align-items-center flex-column" {...props}>
      <ReactLoading type={'spin'} color={'#D1CBCC'} height={80} width={80} />
      <p className="mt-4">{children}</p>
    </div>
  );
}