import React from 'react';

import { Header } from '../../components';

function NotFound() {
  return (
    <div className="not-found">
      <Header title="Ops.."  />
      <p className="text-center">Página não encontrada!</p>
    </div>
  );
}

export default NotFound;