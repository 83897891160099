import React from 'react';

const Datagrid = ({ children, className, ...props }) => {
	return (
		<div className="datagrid-wrapper">
			<div className={`datagrid${className ? ` ${className}` : ''}`} {...props}>
				{children}
			</div>
		</div>
	);
};

const THead = ({ children, className, ...props }) => {
	return (
		<div
			className={`datagrid__head${className ? ` ${className}` : ''}`}
			{...props}
		>
			{children}
		</div>
	);
};
const TBody = ({ children, ...props }) => {
	return (
		<div className="datagrid__body" {...props}>
			{children}
		</div>
	);
};
const TFooter = ({ children, ...props }) => {
	return (
		<div className="datagrid__footer" {...props}>
			{children}
		</div>
	);
};
const Tr = ({ children, ...props }) => {
	return (
		<div className="datagrid__line" {...props}>
			<div className="content">{children}</div>
		</div>
	);
};
const Td = ({ children, size, align, style, ...props }) => {
	return (
		<div
			className="datagrid__column"
			style={{ ...style, flex: size, alignItems: align }}
			{...props}
		>
			{children}
		</div>
	);
};

Datagrid.Head = THead;
Datagrid.Body = TBody;
Datagrid.Footer = TFooter;
Datagrid.Line = Tr;
Datagrid.Column = Td;

export { Datagrid };
