import React from 'react';
import { Link } from 'react-router-dom';
import { Datagrid } from '../Datagrid';

export function DepartmentDatagrid({ items, title, year }) {
	return (
		<>
			<Datagrid.Head className="invert">
				<Datagrid.Line>
					<Datagrid.Column size={0.6}>{title}</Datagrid.Column>
					<Datagrid.Column size={0.2} align="center">
						Instrutores
					</Datagrid.Column>
					<Datagrid.Column size={0.2} align="center">
						Estudantes
					</Datagrid.Column>
				</Datagrid.Line>
			</Datagrid.Head>
			<Datagrid.Body>
				{items.map(({ _id, name, instructors, students }, index) => {
					return (
						<Datagrid.Line key={index}>
							<Datagrid.Column size={0.6}>
								<Link to={`/church/${_id}/view?year=${year}`}>{name}</Link>
							</Datagrid.Column>
							<Datagrid.Column size={0.2} align="center">
								{instructors}
							</Datagrid.Column>
							<Datagrid.Column size={0.2} align="center">
								{students}
							</Datagrid.Column>
						</Datagrid.Line>
					);
				})}
			</Datagrid.Body>
		</>
	);
}
