import React, { useMemo } from 'react';

export const Header = ({
	title,
	church,
	district,
	region,
	conference,
	isLoading,
	type,
}) => {
	const subtitle = useMemo(() => {
		let title = '';

		if (type === 'region-department') {
			if (conference) title = conference;
			if (church) title = `${church} - ${title}`;
			return title;
		}

		if (conference) title = conference;
		if (region) title = `${title} (${region})`;
		if (district) title = `${district} - ${title}`;
		if (church) title = `${church} - ${title}`;
		return title;
	}, [type, conference, region, district, church]);

	return (
		<div className="header">
			<header className="container">
				<div className="row">
					<div className="col-md-12 text-center">
						<h1>Através de Nós</h1>
						<h2>{title ? title : 'Acompanhamento de Estudos Bíblicos'}</h2>
						{isLoading ? '' : subtitle}
					</div>
				</div>
			</header>
		</div>
	);
};
