import React from 'react';
function Bar( props ) {
  return (
    <div className="bar text-center">
      <div className="label">{props.label}</div>
      <div className="progress-bar">
        <div className="progress" style={ { width: `${props.percent}%` }}></div>
      </div>
      <div className="percent-label">{Math.round(props.percent)}%</div>
    </div>
  )
}

export default Bar;