import React, { useEffect, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';

import api from '../../services/api';
import { Header, Main, Loading, Section, Datagrid } from '../../components';
import { DepartmentDatagrid } from '../../components/DepartmentDatagrid';
import { useSearchParams } from '../../hooks/useSearchParams';

function District(props) {
	const searchParams = useSearchParams();
	const yearFilter = searchParams.get('year') || new Date().getFullYear();
	const { district_id } = useParams();

	const [isLoading, setIsLoading] = useState(true);
	const [districtName, setDistrictName] = useState('');
	const [conferenceName, setConferenceName] = useState('');
	const [regionName, setRegionName] = useState('');
	const [instructorsTotal, setInstructorsTotal] = useState(0);
	const [studentsTotal, setStudentsTotal] = useState(0);

	const [churches, setChurches] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [pathfinders, setPathfinders] = useState([]);
	const [adventurers, setAdventurers] = useState([]);

	useEffect(() => {
		api
			.get(`/districts/${district_id}?year=${yearFilter}`)
			.then(({ data }) => {
				setDistrictName(data.name);
				setRegionName(data.region?.name);
				setConferenceName(
					data.conference.abbr ? data.conference.abbr : data.conference.name
				);
				setChurches(data.churches.filter((it) => it.type === 'church'));
				setDepartments(
					data.churches.filter((it) => it.type === 'church-department')
				);
				setPathfinders(
					data.churches.filter((it) => it.type === 'church-pathfinder')
				);
				setAdventurers(
					data.churches.filter((it) => it.type === 'church-adventurer')
				);
				setInstructorsTotal(data.numbers.instructorsTotal);
				setStudentsTotal(data.numbers.studentsTotal);
				setIsLoading(false);
			})
			.catch((e) => {
				console.error(e);
			});
	}, [district_id, yearFilter]);

	return (
		<>
			<Header
				title="Acompanhamento de Estudos Bíblicos"
				district={districtName}
				conference={conferenceName}
				region={regionName}
			/>

			<Main>
				<Section>
					{isLoading ? (
						<Loading>Carregando dados...</Loading>
					) : (
						<Datagrid className="blocked stripped">
							<DepartmentDatagrid
								title="Igrejas"
								items={churches}
								year={yearFilter}
							/>
							{adventurers.length > 0 && (
								<DepartmentDatagrid
									title="Aventureiros"
									items={adventurers}
									year={yearFilter}
								/>
							)}
							{pathfinders.length > 0 && (
								<DepartmentDatagrid
									title="Desbravadores"
									items={pathfinders}
									year={yearFilter}
								/>
							)}
							{departments.length > 0 && (
								<DepartmentDatagrid
									title="Ministério Jovem"
									items={departments}
									year={yearFilter}
								/>
							)}
							<Datagrid.Footer>
								<Datagrid.Line>
									<Datagrid.Column size={0.6}>Total</Datagrid.Column>
									<Datagrid.Column size={0.2} align="center">
										{instructorsTotal}
									</Datagrid.Column>
									<Datagrid.Column size={0.2} align="center">
										{studentsTotal}
									</Datagrid.Column>
								</Datagrid.Line>
							</Datagrid.Footer>
						</Datagrid>
					)}
				</Section>
			</Main>
		</>
	);
}

export default withRouter(District);
