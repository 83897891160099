import React from 'react';

export const Footer = ({ children, ...props }) => {
  return (
    <footer {...props}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {children}
          </div>
        </div>
      </div>
    </footer>
  );
}